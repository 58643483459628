<template>
    <div class="details_container">
        <div class="warpper">
            <div class="tit_row">
                <h1>{{ details.title }}</h1>
                <div class="info">
                    <p style="cursor: pointer;">展会来源：<span @click="openPage(details.activeUrl)">{{ details.source }}</span>
                    </p>
                    <p>城市：{{ details.province }}
                        <i v-if="details.province !== details.city">
                            {{ details.city }}
                        </i>
                    </p>
                    <p>时间：{{ details.activStartTime }} 至 {{ details.activEndTime }}</p>
                </div>
            </div>
            <div class="content_page">
                <div class="content_box">
                    <div class="content" v-html="details.content"></div>
                    <div class="link_box" v-if="details.activeUrl">
                        <div class="name">展会链接：</div>
                        <p class="link" @click="openPage(details.activeUrl)">{{ details.activeUrl }}</p>
                    </div>
                </div>
                <div class="page_box">
                    <div class="li" v-if="prevNext.preData.id" @click="onPrevNext(prevNext.preData.id, 'prePage')">
                        <h2>上一篇</h2>
                        <h3>{{ prevNext.preData.title }}</h3>
                        <p class="txt textOverflowTwo">{{ prevNext.preData.des }}</p>
                        <p class="date">{{ prevNext.preData.createTime }}</p>
                    </div>
                    <div class="li" v-if="prevNext.nextData.id" @click="onPrevNext(prevNext.nextData.id, 'nextPage')">
                        <h2>下一篇</h2>
                        <h3>{{ prevNext.nextData.title }}</h3>
                        <div class="txt textOverflowTwo">{{ prevNext.nextData.des }}</div>
                        <p class="date">{{ prevNext.nextData.createTime }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue';
import { apiActivGetActivInfo, apiActivGetPreviAndNext } from '@/request/api';
import { useRoute, useRouter } from 'vue-router';
import withLoading from '@/utils/loading';
import useNavStore from '@/store/useNavStore';

const $route = useRoute();
const $router = useRouter();
const navStore = useNavStore();

const activeId = $route.params.id;
const query = $route.query;
const details = ref({});// 详情
// 上一条/下一条
const prevNext = reactive({ preData: {}, nextData: {} });
// 返回的pageNum
const pageNums = reactive({
    prePage: null,
    nextPage: null
})

onActivated(() => {
    if (details.value.title) {
        const title = details.value.title || '展会详情';
        document.title = title;// 修改浏览器title
        navStore.updateTitle($route.path, title);// 修改头部导航标签名称
    }
})

// 获取数据
async function getData() {
    const { data } = await withLoading(apiActivGetActivInfo)({ id: activeId });
    details.value = data || {};
    const title = details.value.title || '展会详情'
    document.title = title;// 修改浏览器title
    navStore.updateTitle($route.path, title);// 修改头部导航标签名称
}

// 获取上下
async function getPrevNext() {
    const params = { ...query, id: activeId, pageNum: query.pageNum || 1 };
    const { data } = await apiActivGetPreviAndNext(params);
    prevNext.preData = data?.preData || {};
    prevNext.nextData = data?.nextData || {};
    pageNums.prePage = data.prePage;
    pageNums.nextPage = data.nextPage;
}

// 跳转上下
function onPrevNext(id, type) {
    navStore.setIsReplace(true);
    $router.push({ path: '/activity/details/' + id, query: { ...query, pageNum: pageNums[type] } });
}

// 打开
function openPage(url) {
    window.open(url, "_blank");
}

getData();
getPrevNext();

</script>

<style lang="scss" scoped>
.details_container {
    --list_top_bottom_padding: 32px;
    padding: var(--list_top_bottom_padding) 56px;
    min-height: calc(100vh - var(--home-header-height));

    .warpper {
        width: 1080px;
        min-height: calc(100vh - var(--home-header-height) - var(--list_top_bottom_padding) - var(--list_top_bottom_padding));
        margin: 0 auto;
        background-color: #fff;
        border-radius: 8px;

        .tit_row {
            padding: 24px;

            h1 {
                font-size: 32px;
                color: #2E303A;
                margin-bottom: 8px;
            }

            .info {
                display: flex;
                flex-wrap: wrap;

                p {
                    color: #999B9F;
                    font-size: 16px;
                    padding-right: 32px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    span {
                        font-size: inherit;
                        color: #3EA2FF;
                    }

                    i {
                        color: inherit;
                        font-size: inherit;
                    }
                }
            }
        }

        .content_page {
            display: flex;
            padding: 0 24px;

            .content_box {
                flex: 1;
                padding-right: 32px;
                padding-bottom: 32px;

                .content {
                    padding-bottom: 32px;

                    :deep(*) {
                        max-width: 100%;
                    }

                    :deep(h1),
                    :deep(h2),
                    :deep(h3),
                    :deep(h4),
                    :deep(h5),
                    :deep(h6) {
                        font-size: revert;
                    }

                    :deep(li) {
                        list-style: inside;
                    }

                    :deep(strong) {
                        font-size: inherit;
                    }
                }

                .link_box {
                    display: flex;
                    align-items: center;

                    .name {
                        white-space: nowrap;
                        color: #55575F;
                        font-size: 16px;
                    }

                    .link {
                        color: #3EA2FF;
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
            }

            .page_box {
                width: 312px;

                .li {
                    border: 1px solid #D9D9D9;
                    padding: 16px;
                    padding-top: 32px;
                    cursor: pointer;

                    &:first-child {
                        margin-bottom: 18px;
                    }

                    h2 {
                        font-size: 20px;
                        color: #454750;
                        padding-bottom: 40px;
                    }

                    h3 {
                        font-size: 16px;
                        color: #2C2F39;
                        padding-bottom: 8px;
                    }

                    .txt {
                        color: #A9ABAF;
                        font-size: 14px;
                    }

                    .date {
                        color: #A9ABAF;
                        font-size: 14px;
                        padding-top: 8px;
                    }
                }
            }
        }
    }
}
</style>